import React, { useEffect, useState } from "react";
import { List, Typography } from 'antd';

import {
    Table,
    Space,
    useTable,
    DateField,
} from "@pankod/refine-antd";
import { ICompany, VaFundingBank } from "interfaces";
import { CreateDecentroAccountModel } from "../models/create-decentro-account.model";
import { RecalculateBalancesOfVirtualAccountsModel } from "../models/recalculate-va-balance.model";
import { CreateNewRepaymentVaButtonModel } from "../models/create-new-repayment-va.model";
import { CreateEaseBuzzWireVaModel } from "../models/create-eb-wire-va.model";
import { ActivateDeactivateEbVaStatusModel } from "../models/activate-deactivate-eb-va-status.model";
import { ActivateDeactivateDecentroVaStatusModel } from "../models/activate-deactivate-decentro-va-status.model";

const { Title, Text } = Typography;

interface VAProp {
    companyDetail: ICompany
    refetchCompanyDetail: (shouldRefetch: boolean) => void;
}

export const VaListingTable: React.FC<VAProp> = ({ companyDetail, refetchCompanyDetail }) => {
    let [tableRefetch, setTableRefetch] = useState(false);

    useEffect(() => {
        setTableRefetch(false)
    }, [tableRefetch])

    let { tableProps }: any = useTable({
        dataProviderName: "grapghQl",
        resource: 'allVirtualAccountsForCompanyViaInternalPortalPaginated',
        syncWithLocation: true,
        metaData: {
            companyId: companyDetail.id,
            excludeNonActiveBankAccounts: false,
            tableRefetch: tableRefetch,
        },
    });

    return (
        <>
            <List >
                <List.Item>
                    <Space align="center">
                        <Title level={5}> Kodo VA Accounts </Title>
                        {companyDetail && <CreateNewRepaymentVaButtonModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />}
                    </Space>
                    {companyDetail && <RecalculateBalancesOfVirtualAccountsModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />}
                </List.Item>
            </List>

            <Table {...tableProps } rowKey="id">
                <Table.Column
                    title="Integration"
                    dataIndex="bankAccount"
                    render={(_, record: any) => {
                        const integratorName = record?.serviceProviderIntegration?.serviceProviderIntegrator?.name;
                        return (
                            <Space>
                                <Text> {integratorName || '-'}</Text>
                            </Space>
                        );
                    }}
                />
                <Table.Column title="Account Number" dataIndex="accountNo" />
                <Table.Column title="IFSC" dataIndex="ifsc" />
                <Table.Column title="Balance" dataIndex="balance" />
                <Table.Column 
                    title="Added by" 
                    dataIndex="activatedBy" 
                    render={(value: any) => (
                        value.displayName
                    )} />
                <Table.Column
                    title="Added on"
                    dataIndex="activatedAt"
                    render={(value: any) => (
                        <Space>
                            
                            {
                                <DateField className="ml-2" value={value} format="LL" />
                            }
                        </Space>
                    )}
                />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, element: any) => (   
                        element.decentroVirtualAccount ?
                        <ActivateDeactivateDecentroVaStatusModel bankAccountDetails={element} setTableRefetch={setTableRefetch}/> :
                        <ActivateDeactivateEbVaStatusModel bankAccountDetails={element} setTableRefetch={setTableRefetch}/>
                    )}
                />
            </Table>

            {companyDetail && <CreateDecentroAccountModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />}
            {companyDetail && <CreateEaseBuzzWireVaModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />}
        </>
    );
};
