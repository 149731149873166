import React, { useState } from "react";
import { List, Typography } from 'antd';

import {
    Table,
    useModalForm,
    Modal,
    Form,
    Space,
    useTable,
    Row,
    Col,
    Input,
    Icons,
    Button,
    DateField,
} from "@pankod/refine-antd";
import { ICompany, VaFundingBank } from "interfaces";
import { CloseVaFundingAccountButtonModel } from "../models/close-va-funding-account.model";
import { CrudFilters } from "@pankod/refine-core";

const { Title, Text } = Typography;

interface VAProp {
    companyDetail: ICompany
}

export const VaFundingAccountsTable: React.FC<VAProp> = ({ companyDetail }) => {
    let [tableRefetch, setTableRefetch] = useState();


    let { tableProps, searchFormProps } = useTable({
        dataProviderName: "grapghQl",
        resource: 'fundingBankAccountForCompany',
        syncWithLocation: true,
        metaData: {
            includeDeleted: true, 
            tableRefetch: tableRefetch,
            companyId: companyDetail.id
        },
        initialSorter: [
            {
                field: "createdAt",
                order: "desc",
            },
        ],
        onSearch: (params: any) => {
            const filters: CrudFilters = [];
            const { tableRefetch } = params;
            setTableRefetch(tableRefetch)
            return filters;
        },
    });


    const refetchFundingBankAccountList = (message: boolean) => {
        if (searchFormProps.form?.getFieldValue('tableRefetch')) {
            searchFormProps.form?.setFieldsValue({ tableRefetch: null });
        } else {
            searchFormProps.form?.setFieldsValue({ tableRefetch: message });
        }

        searchFormProps.form?.submit();
    };

    return (
        <>
            <List >
                <List.Item>
                    <Title level={5}>Authorized Accounts (Kodo VA Funding)</Title>
                </List.Item>
            </List>
            <Form layout="vertical" {...searchFormProps}>
                <Row gutter={[16, 16]} hidden={true}>
                    <Col lg={6} xs={6}>
                        <Form.Item hidden={true} label="Table Refetch field" name="tableRefetch">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Table {...tableProps} pagination={false} rowKey="id">
                <Table.Column
                    title="Integration"
                    dataIndex="bankAccount"
                    render={(_, record: VaFundingBank) => {
                        const integratorName = record?.bankAccount?.serviceProviderIntegration?.serviceProviderIntegrator?.name;
                        return (
                            <Space>
                                <Text> {integratorName || '-'}</Text>
                            </Space>
                        );
                    }}
                />
                <Table.Column title="Account Name" dataIndex="accountName" />
                <Table.Column title="Account Number" dataIndex="accountNumber" />
                <Table.Column title="IFSC" dataIndex="ifsc" />
                <Table.Column title="Added by" dataIndex="addedBy" />
                <Table.Column title="" dataIndex="addedOn" />
                <Table.Column
                    title="Added on"
                    dataIndex="addedOn"
                    render={(value) => (
                        <Space>
                            
                            {
                                <DateField className="ml-2" value={value} format="LL" />
                            }
                        </Space>
                    )}
                />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, element: VaFundingBank) => (
                        <Space>
                            {
                                !element.isDeleted && <CloseVaFundingAccountButtonModel
                                    refetchCompanyDetail={refetchFundingBankAccountList}
                                    fundingBankAccountValidationRequestId={element.lastValidationRequestId}
                                    companyId={companyDetail.id} bankAccountId={element.bankAccount?.id} />
                            }
                            {
                                element.isDeleted && <Button disabled> Removed on <DateField className="ml-2" value={element.deletedOn} format="LL" /></Button>
                            }
                        </Space>
                    )}
                />
            </Table>
        </>
    );
};
