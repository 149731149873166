import React, { useState } from "react";
import {
    Button,
    Modal,
    Form,
    Input,
    Space,
    Switch,
    Row,
    Col,
    useModalForm,
    Select,
    Typography,
    Tooltip,
    notificationProvider,
    message,
} from "@pankod/refine-antd";
import NestsxCrud from "dataProvider/nestjs-crud";
import { API_URL } from "App";
import { ServiceProviderIntegration, ServiceProviderIntegratorKey } from "interfaces/bank-account/transparent";


export const CreateEaseBuzzWireVaModel: React.FC<{
    refetchCompanyDetail: any;
    companyId: string;
}> = ({ refetchCompanyDetail, companyId }) => {
    const [isVerifying, setIsVerifying] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [integrations, setIntegrations] = useState<{ label: string, value: string }[]>([]);

    const {
        modalProps: createEaseBuzzWireModel,
        formProps: createEaseBuzzWireForm,
        show: showEaseBuzzWireVaCreationModal,
    } = useModalForm({
        resource: "createEasebuzzMerchantVirtualAccountForCompany",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            companyId,
        },
        redirect: false,
        onMutationSuccess: () => {
            refetchCompanyDetail(true);
        },
    });

    createEaseBuzzWireModel.title = "Create EaseBuzz Wire VA";
    createEaseBuzzWireModel.centered = true;
    createEaseBuzzWireModel.width = 800;

    const handleShowModal = () => {
        setIsVerifying(false);
        setIsVerified(false);
        setIntegrations([]);
        createEaseBuzzWireForm.form?.resetFields();

        if (integrations?.length) {
            showEaseBuzzWireVaCreationModal();
        } else {
            const accessTokenRaw = sessionStorage.getItem('konsole-token');
            fetch(`${API_URL}/banking-transparent-access/internal-portal/service-provider-integration/merchant/${ServiceProviderIntegratorKey.EASEBUZZ}`, {
                method: 'GET',
                headers: {
                    authorization: accessTokenRaw ? `Bearer ${accessTokenRaw}` : '',
                },
            }).then(async (res) => {
                const integrations = (await res.json() || []) as ServiceProviderIntegration[];
                setIntegrations(integrations.map(it => ({ label: `${it.description} - (${it.key})`, value: it.key })));
                showEaseBuzzWireVaCreationModal();
            }).catch(() => {
                message.error('Unable to find any merchant integrations');
            })
        }
    };

    const handleVerify = async (): Promise<void> => {
        setIsVerifying(true);
        const showErrorToast = (err) => {
            setIsVerifying(false);
            notificationProvider.open({
                message: err?.message || err,
                type: 'error',
                description: `Verification Failed`,
            });
        };
        const formValues = createEaseBuzzWireForm?.form?.getFieldsValue(true);
        const { key, salt, payload: { account_number, ifsc }, serviceProviderIntegrationKey } = formValues?.input || {};
        if (!(key && salt && account_number && ifsc)) {
            setIsVerifying(false);
            showErrorToast('Mandatory fields are missing');
            return;
        }
        try {
            const dataProvider = NestsxCrud('') as any;
            if (dataProvider && createEaseBuzzWireForm?.form) {
                const formValues = createEaseBuzzWireForm.form.getFieldsValue(true);
                let url = `${API_URL}/easebuzz-client/virtual-accounts/wire/verify?key=${key}&salt=${salt}&account_number=${account_number}&ifsc=${ifsc}&serviceProviderIntegrationKey=${serviceProviderIntegrationKey}`;
                const data = await dataProvider.custom({
                    url,
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', },
                });
                if (data?.data) {
                    const va = data.data;
                    if (va?.id) {
                        createEaseBuzzWireForm.form.setFieldsValue({
                            input: {
                                ...formValues.input,
                                payload: {
                                    ...formValues.input?.payload,
                                    id: va.id,
                                    is_active: va.is_active,
                                    bank_name: va.bank_name,
                                    status: va.status,
                                },
                            },
                        });
                    }
                    setIsVerified(true);
                    setIsVerifying(false);
                } else {
                    showErrorToast('Something went wrong');
                }
                setIsVerifying(false);
            } else {
                setIsVerifying(false);
            }
        } catch (err: any) {
            showErrorToast(err);
        }
    };


    return (
        <Space>
            <Button onClick={() => handleShowModal()}>
                Create EaseBuzz Wire VA
            </Button>

            <Modal {...createEaseBuzzWireModel}
                footer={[
                    <Button key="back" onClick={createEaseBuzzWireModel.onCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={isVerifying}
                        onClick={() => {
                            const formValues = createEaseBuzzWireForm?.form?.getFieldsValue(true);
                            const { key, salt, payload: { id, account_number, ifsc } } = formValues?.input || {};
                            const hasValues = (key && salt && account_number && ifsc && id);
                            if (!isVerified || !hasValues) {
                                notificationProvider.open({
                                    message: 'Please verify the account details before saving.',
                                    type: 'error',
                                    description: `Verification Required`,
                                });
                            } else {
                                createEaseBuzzWireForm.form?.submit();
                            }
                        }}
                    >Save</Button>,
                ]}
            >
                <Form
                    {...createEaseBuzzWireForm}
                    layout="vertical"
                    initialValues={{
                        input: {
                            companyId,
                            serviceProviderIntegrationKey: null,
                            key: "",
                            salt: "",
                            payload: {
                                id: "",
                                account_number: "",
                                is_active: true,
                                bank_name: "",
                                status: "active",
                                ifsc: "",
                            },
                        },
                    }}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Service Provider Integration"
                                name={["input", "serviceProviderIntegrationKey"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a value",
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue=""
                                    options={integrations}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["input", "payload", "account_number"]}
                                label="Account Number"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the account number",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["input", "payload", "ifsc"]}
                                label="IFSC Code"
                                rules={[{ required: true, message: "Please enter the IFSC code" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name={["input", "key"]}
                                label="Key"
                                rules={[{ required: true, message: "Please enter the key" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name={["input", "salt"]}
                                label="Salt"
                                rules={[{ required: true, message: "Please enter the salt" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <Button type="primary" style={{ height: "32px" }} onClick={() => handleVerify()} disabled={isVerifying}>
                                Verify
                            </Button>
                        </Col>
                    </Row>

                    {isVerified && (
                        <>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name={["input", "payload", "id"]}
                                        label="VA ID"
                                        rules={[{ required: true, message: "Please enter the VA id" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={["input", "payload", "bank_name"]}
                                        label="Bank Name"
                                    >
                                        <Input readOnly />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}

                </Form>
            </Modal>
        </Space>
    );
};