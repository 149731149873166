import React, { useEffect, useState, useMemo } from "react";
import {
    useModalForm, 
    Modal, 
    Space, 
    Form,
    Button,
    Checkbox,
} from "@pankod/refine-antd";
import { VaFundingBank } from "interfaces";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

export const ActivateDeactivateEbVaStatusModel: React.FC<{ bankAccountDetails: any, setTableRefetch?: any }> = ({ bankAccountDetails, setTableRefetch}) => {
    const { id, isActive, serviceProviderIntegration: { serviceProviderIntegrator: { name } } } = bankAccountDetails;

    let actionName = isActive ? 'Deactivate Account' : 'Reactivate Account'
    const [checked, setChecked] = useState(false);

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };

    const { 
        modalProps: deactivateOrActivateVAAccount, 
        formProps: formProp,
        show: deactivateOrActivateVAAccountShow, 
        close: deactivateOrActivateVAAccountClose
    } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "deactivateOrActivateVaForCompany",
        action: "create",
        metaData: { 
            bankAccountId: id,
            isActive: !isActive,
            initiateAccountActivationRequest: false,
            markInternallyOnly: checked
        },
        redirect: false,
        onMutationSuccess() {
            setChecked(false);
            setTableRefetch(true)
        }
    });
    deactivateOrActivateVAAccount.title = 'Update Kodo VA Account Status'
    deactivateOrActivateVAAccount.centered = true
    deactivateOrActivateVAAccount.width = 400
    deactivateOrActivateVAAccount.okText = actionName
    deactivateOrActivateVAAccount.cancelText = 'Go Back'
    // deactivateOrActivateVAAccount.okButtonProps = {
    //     style: {'backgroundColor': '#000000'}
    // }
    // deactivateOrActivateVAAccount.onOk = () => {
    //     deactivateOrActivateVAAccountClose();
    //     deactivateOrActivateVAAccountSubmit();
    // }
    deactivateOrActivateVAAccount.onCancel = () => {
        setChecked(false);
        deactivateOrActivateVAAccountClose();
    }

    return (
        <Space> 
             <Button danger={isActive} onClick={() => { deactivateOrActivateVAAccountShow() }} type="primary"> {actionName} </Button>
            <Modal {...deactivateOrActivateVAAccount}>
                <>{isActive ? 'Disable': 'Enable'} the {name} Kodo Va Account?</>
                <Form {...formProp} layout="vertical">
                    <>{isActive && 'If you deactivate it, the customer won’t be able to add funds to this account. Are you sure you want to proceed with?'}</>
                    <Form.Item >
                        <Checkbox checked={checked} onChange={onChange}>
                            No Operation required at Integrator's end
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    )
}