import React, { useEffect, useState } from "react";

import {
    useModalForm, Modal, Space, Form,
    Button,
    Select,
} from "@pankod/refine-antd";
import { Typography } from 'antd';

import { ICompany } from "../../interfaces";
const { Title, Text } = Typography;

export const AccountManagersModel: React.FC<{ refreshAccountManager: any, companyDetail: ICompany | undefined, accountMangerSelectProps: any }> = ({ companyDetail, accountMangerSelectProps, refreshAccountManager }) => {

    const [accountID, setaccountID] = useState();

    useEffect(() => {

    });

    //account manager Edit Model
    const { modalProps: accountEditModel, formProps: accountEditForm, show: accountEditShow, close: accountEditClose } = useModalForm<ICompany>({
        dataProviderName: "grapghQl",
        resource: "assignCompanyAccountManager",
        action: "create",
        metaData: { companyId: companyDetail?.id, id: accountID },
        redirect: false,
        onMutationSuccess() {
            refreshAccountManager(true)
        }
    });
    accountEditModel.title = 'Edit Account Manager'
    accountEditModel.centered = true
    accountEditModel.width = 400
    accountEditModel.onCancel = () => {
        accountEditForm.form?.resetFields();
        accountEditClose();
    }

    return ( 
        <Space>
            {
                (companyDetail) ? <Button onClick={() => { setaccountID(companyDetail?.primaryAccountManagerUser?.id); accountEditShow() }} type="link">
                    Edit Manager
                </Button> : ""
            }

            <Modal {...accountEditModel}>

                <Title level={5}>Account Manager</Title>
                {
                    (companyDetail?.primaryAccountManagerUser) ? <Text>{companyDetail?.primaryAccountManagerUser?.manager?.firstName + ' ' + companyDetail?.primaryAccountManagerUser?.manager?.lastName}</Text> : ''
                }
                <Form {...accountEditForm} layout="vertical">
                    <Form.Item
                        label="Account Manager"
                        name={"accountManagerUserId"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...accountMangerSelectProps} />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};