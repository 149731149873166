interface ServiceProviderIntegrator {
    id: string;
    version: number;
    key: string;
    name: string;
    description: string;
    isActive: boolean;
    activatedAt: string;
    isDefault: boolean;
    createdAt: string;
    updatedAt?: string;
    createdById: string;
    updatedById?: string;
    lastUpdateNotes?: string;
    lastUpdateNotesByActor?: string;
}

export interface ServiceProviderIntegration {
    id: string;
    version: number;
    serviceProviderIntegratorId: string;
    key: string;
    bankCode: string;
    description: string;
    isDefault: boolean;
    isMerchantSetup: boolean;
    isUpiEnabled: boolean;
    createdAt: string;
    updatedAt?: string;
    createdById: string;
    updatedById?: string;
    lastUpdateNotes?: string;
    lastUpdateNotesByActor?: string;
    serviceProviderIntegrator: ServiceProviderIntegrator;
}

export enum ServiceProviderIntegratorKey {
    EASEBUZZ = 'EASEBUZZ',
    DECENTRO = 'DECENTRO',
}